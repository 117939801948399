import 'ie11-custom-properties';
import 'whatwg-fetch';
import 'core-js/features/global-this';
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';
import 'core-js/modules/es.array.find';
import 'core-js/modules/es.object.assign';
import 'element-matches-polyfill';
import 'element-closest-polyfill';
import 'custom-event-polyfill';
import '@common/js/polyfills/isNaN';
import {EventTarget} from 'event-target-shim';
window.EventTarget = EventTarget;
